// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/build-modules/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../layers/paketo-buildpacks_npm-install/build-modules/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uUk7CzF5xEk1XyhwsJzY {
  font-size: 19px;
  margin-bottom: 30px;
  text-align: left;
  max-width: 800px;
}

.lmQTOSFsv8Dcj4lFhl8l {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 19px;
  margin-bottom: 10px;
  font-weight: normal;
}

.ReactModal__Overlay {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5)!important;
}

.ReactModal__Content {
  background: #ffffff;
  margin: 0;
  padding: 30px!important;
  bottom: auto !important;
  right: auto !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-height: calc(100vh - 30px);
  width: calc(100vw - 30px);
}

.iIbNYPOtt6E5sOWUa3Fa {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  align-items: baseline;
}

@media (max-width: 755px) {
  .HRi6yBfWVHamby_rZMBd {
    flex: 1 1 100%;
  }
}

@media (min-width: 500px) {
  .ReactModal__Content {
    padding: 52px!important;
  }

  .lmQTOSFsv8Dcj4lFhl8l {
    width: auto;
    margin-right: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./react-components/src/components/CookiesModal/CookiesModal.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8CAA8C;AAChD;;AAEA;EACE,mBAAmB;EACnB,SAAS;EACT,uBAAuB;EACvB,uBAAuB;EACvB,sBAAsB;EACtB,mBAAmB;EACnB,oBAAoB;EACpB,gCAAgC;EAChC,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE;IACE,cAAc;EAChB;AACF;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;AACF","sourcesContent":[".synopsis {\n  font-size: 19px;\n  margin-bottom: 30px;\n  text-align: left;\n  max-width: 800px;\n}\n\n.greatButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  font-size: 19px;\n  margin-bottom: 10px;\n  font-weight: normal;\n}\n\n:global .ReactModal__Overlay {\n  z-index: 1000;\n  background-color: rgba(0, 0, 0, 0.5)!important;\n}\n\n:global .ReactModal__Content {\n  background: #ffffff;\n  margin: 0;\n  padding: 30px!important;\n  bottom: auto !important;\n  right: auto !important;\n  top: 50% !important;\n  left: 50% !important;\n  transform: translate(-50%, -50%);\n  max-height: calc(100vh - 30px);\n  width: calc(100vw - 30px);\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: start;\n  flex-wrap: wrap;\n  align-items: baseline;\n}\n\n@media (max-width: 755px) {\n  .cookieLink {\n    flex: 1 1 100%;\n  }\n}\n\n@media (min-width: 500px) {\n  :global .ReactModal__Content {\n    padding: 52px!important;\n  }\n\n  .greatButton {\n    width: auto;\n    margin-right: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"synopsis": `uUk7CzF5xEk1XyhwsJzY`,
	"greatButton": `lmQTOSFsv8Dcj4lFhl8l`,
	"buttonContainer": `iIbNYPOtt6E5sOWUa3Fa`,
	"cookieLink": `HRi6yBfWVHamby_rZMBd`
};
export default ___CSS_LOADER_EXPORT___;
